import React,{Fragment} from 'react';
import Logo from '../../Logo/Logo';
import NavigationItems from '../NavigationItems/NavigationItems';
import classes from './SideDrawer.module.css';
import BackDrop from '../../UI/BackDrop/BackDrop';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';

const sideDrawer =(props)=>{
    let attachedClasses=[classes.SideDrawer,classes.Close];
    if(props.open){
        attachedClasses=[classes.SideDrawer,classes.Open];
    }
return(
<Fragment>
    <BackDrop show={props.open} clicked={props.closed}/>
    <div  className={attachedClasses.join(' ')}>

             <div className='d-flex justify-content-between mb-3'>
                <div style={{width:"100px"}}>

                <Logo style={{marginBottom:"32px"}}  height="60px"/>
                </div>
              {/* <DrawerToggle clicked={props.drawerToggleClicked}/> */}
              
              <div class="close-button" 
              onClick={props.drawerToggleClicked}
              style={{
                 width: "40px",  /* Adjust size as needed */
                 height: "40px",
                 backgroundColor: "white",
                 /* Border color matching the background */
                 color: "#0055A4", /* Text color */
                 fontSize: "20px",
                 fontWeight: "bold",
                 display: "flex",
                justifyContent: "center",
                alignItems: "center",
                 cursor: "pointer",
                 borderRadius: "4px", /* Slightly rounded corners */
                 boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.3)",
              }}>X</div>


             </div>
        <nav>
            <NavigationItems/>
        </nav>

    </div>
</Fragment>
)
}
export default sideDrawer;