import React from 'react';
import useCheckSuperAdmin from '../../hooks/useCheckSuperAdmin';
import classes from './Logo.module.css';
const Logo =(props)=>{
    const checkIfAdmin = useCheckSuperAdmin();

    let emaildomain = localStorage.getItem("emaildomain")
   
    if(checkIfAdmin){
      let selected_school  = localStorage.getItem("selected_school")
      emaildomain =JSON.parse(selected_school)?.value
    }
    
return(
    <div className={classes.Logo} style={{height:props.height , width:"100px"}}>
       <a href={`/`}> <img src={`/assets/${emaildomain}/logo.png`}  alt="logo"/></a>
    </div>
)
};
export default Logo;