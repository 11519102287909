import React, { Fragment, useEffect, useState } from 'react';
import classes from './PublishedForms.module.css';
import Card from '@mui/material/Card';
import { Row } from 'reactstrap';
import Input from '../../components/Input/Input';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router';
import { BASE_URL } from '../../variables/api';
import useCheckSuperAdmin from '../../hooks/useCheckSuperAdmin';

const PublishForms = (props) => {
    const checkIfAdmin = useCheckSuperAdmin();

  let emaildomain = localStorage.getItem("emaildomain")
 
  if(checkIfAdmin){
    let selected_school  = localStorage.getItem("selected_school")
    emaildomain =JSON.parse(selected_school)?.value
  }
    let navigate = useNavigate();
    const id = useParams();
    const [formData, setFormData] = useState({ name: '', FormArr: [], description: '' });
    const [option, setOptions] = useState([]);
    const [add, setAdd] = useState(false);
    const [file, setFile] = useState(null);
    const [valid, setValid] = useState(true);
    const [change, setChange] = useState(false);
    let [validarr, setValidArr] = useState([]);

    const handleClickDetailedView = (questionName) => {
        let url = questionName.replace(/\s/g, '_');
        navigate(`/details-question/${id.id}/${url}`)
    }
    const handleBack = () => {
        navigate(`/`);
    }

    useEffect(() => {
        axios.post(`${BASE_URL}/surveys/getencryptedsurvey.php`, { qrcode: id }).then((res) => {
            console.log(res);
            setFormData(prevstate => ({
                ...prevstate,
                name: res.data.data.survey.name,
                FormArr: res.data.data.survey.questions,
                description: res.data.data.survey.description,
            }))


        }).catch((err) => {
            
            Swal.fire("You have taken this Survey before!!" ,"" ,"info").then(() => {
                if(window.location.href.search("iatseg.org") != -1){

                 window.close();
                }else{
                    window.location.href = "https://ivyis.org"

                }
            })
        })
    }, [add]);

    const handleChange = (event, id) => {
        const name = event.target.name;
        const value = event.target.value;

        let FormArrCopy = [...formData.FormArr];

        FormArrCopy.map((question) => {
            if (question.id === id) {
                question.response = value;
            }
        })
        setFormData(prevstate => ({ ...prevstate, FormArr: FormArrCopy }));
    }
    const handleChangeselect = (event, id) => {
        let FormArrCopy = [...formData.FormArr];

        FormArrCopy.map((question) => {
            if (question.id === id) {
                question.response = event;
                question.options.map((option) => {
                    if (option.value === question.response.value) {
                        option.response = true;
                    }
                })
            }
        })
        setFormData(prevstate => ({ ...prevstate, FormArr: FormArrCopy }));
    }
    const handleChangeDynamicOptions = (event, id, optionNumber) => {
        const name = event.target.name;
        const value = event.target.value;
        const checked = event.target.checked;
        let FormArrCopy = [...formData.FormArr];
        setChange(true);
        FormArrCopy.map((question) => {
            if (question.id === id) {
                question.options.map((option) => {
                    if (option.id === `option-${optionNumber}`) {
                        option.response = checked;
                    }
                    else if (option.response && question.type.value !== "checkbox") {
                        option.response = ''
                    }
                })

            }
        })
        setFormData(prevstate => ({ ...prevstate, FormArr: FormArrCopy }));
    };
    const submitHandler = (e) => {
        e.preventDefault();
        Swal.showLoading();
        const surveyData = new FormData();
        surveyData.append("formData", JSON.stringify(formData));
        const { id: surveyId } = id;
        surveyData.append("id", surveyId);
        formData.FormArr.map((question) => {
            if (question.Question === "file") {
                surveyData.append(`${question.id}`, question.response);
            }
        })
        surveyData.append(`email`, id.email);
        const res = axios.post(`${BASE_URL}/surveys/addpublishresponse.php`, surveyData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            Swal.fire("Thank you for your time ", "", "success").then(() => {
                window.location.href = "https://ivyis.org"
            });

        }).finally(() => {
            // setFormData(prevstate => ({ ...prevstate, FormArr: [] })); setAdd(!add); props.setPending(!props.pending);
        });
    }

    let options = {};
    formData.FormArr.map((m) => {
        if (m.type.value === "select") {
            let o = m.options.map((option) => {
                return { value: option.value, label: `${option.value} ` };
            })
            options[m.id] = o
        }
    })

    const handleChangeFile = (e, id) => {
        const name = e.target.name;
        const value = e.target.value;
        console.log(id)
        let FormArrCopy = [...formData.FormArr];
        console.log("Questions", FormArrCopy);
        let index = FormArrCopy.findIndex((a) => a.id === id);
        if ("file" == name) {
            if (e.target.files === null) return;
            const selectedFile = e.target.files[0];
            console.log(selectedFile);
            setFile(selectedFile);
            FormArrCopy[index].response = selectedFile;
        }
        setFormData(prevState => ({ ...prevState, FormArr: FormArrCopy }));

    }
    const checkboxrequired = (required, id) => {
        let FormArrCopy = [...formData.FormArr];
        let numberofcheckboxes = FormArrCopy?.map((item) => {
            if (item.type.value === "checkbox" && item.required) {
                return item
            }
        });

        let filtered = numberofcheckboxes?.filter((f) => f != undefined)
        let vaildation = new Array();
        filtered?.map((f) => {
            vaildation.push({ id: f.id, status: false });
        })
        let index = FormArrCopy.findIndex((question) => question.id === id);
        let filteredindex = vaildation.findIndex((v) => v.id === id);
        let indicator = true;
        let i = 0;
        if (change) {
            if (required) {
                FormArrCopy[index]?.options?.map((opt, key) => {

                    if (Object.keys(opt).length == 3) {
                        if (opt.response === true) {
                            indicator = indicator && false;
                            vaildation[filteredindex].option = key;
                        }
                        else {

                            indicator = indicator && true;
                        }
                    }
                    else if (Object.keys(opt).length == 2) {
                        indicator = indicator && true;
                    }
                });
                if (indicator === false) {
                    vaildation[filteredindex].status = true;
                    let finalvalid = vaildation[0];
                    console.log(finalvalid, "unique");
                    if (validarr.length < filtered.length) {
                        validarr.push(finalvalid);
                        console.log(validarr, "after push")
                    }
                    let jsonObject = validarr.map(JSON.stringify);
                    console.log(jsonObject);
                    let uniqueSet = new Set(jsonObject);
                    let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
                    validarr = uniqueArray;
                    if (filtered.length == validarr.length) {
                        setValid(false);
                    }
                }
                else {
                    console.log("i am here")

                    vaildation[filteredindex].status = false;
                    validarr.pop();
                    console.log(validarr, "after pop")
                    setValid(true);
                }
            }
            else {
                setValid(true);
            }

            setChange(false);
        }
        return false;
    }
    return (
        <Fragment>

            <div style={{ textAlign: "center", marginTop: "50px" }} className={classes.minus}>

                <img src={`/assets/${emaildomain}/logo.png`} className={classes.img} alt="logo" />
            </div>
            <h2 className={classes.h2} style={{ textAlign: "center" }}>{formData.name}</h2>
            <div className='m-3 mx-5 px-5' dangerouslySetInnerHTML={{ __html: formData.description }}></div>
            <div style={{ display: "flex", justifyContent: "space-evenly" }} >

                <Card className="col-md-11"  >


                    <form onSubmit={submitHandler}>

                        {formData.FormArr.map((t, Qindex) => {

                            if ((t.type.value === "checkbox") || (t.type.value === "radio")) {
                                return (
                                    <div key={`checkbox-${Qindex}`} style={{ padding: "5px", border: "1px solid", marginBottom: "10px" }}>
                                        <div >{t.Question}</div>
                                        <div style={{ textAlign: "center" }} >
                                            {t.options.map((option, optionIndex) => {
                                                if (t.type.value === "radio") {
                                                    return (
                                                        <div className="form-check-inline" key={`checkbox-options-${Qindex}-${optionIndex}`}>
                                                            <Input label={option.value} className="form-check-input" id={option.value + t.id} name={t.Question} type={t.type.value} required={t.required} checked={option.response ? true : false} description={t.description} onChange={(event) => { handleChangeDynamicOptions(event, t.id, `${optionIndex + 1}`, t.Question) }} />
                                                        </div>
                                                    )
                                                }
                                                else {

                                                    return (
                                                        <div className="form-check-inline" key={`checkbox-options-${Qindex}-${optionIndex}`}>
                                                            <Input label={option.value} className="form-check-input" id={option.value + t.id} name={t.Question} type={t.type.value} checked={option.response ? true : false} required={checkboxrequired(t.required, t.id)} description={t.description} onChange={(event) => { handleChangeDynamicOptions(event, t.id, `${optionIndex + 1}`, t.Question) }} />
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>
                                )

                            }
                            else if (t.type.value === "linescale") {
                                return (
                                    <div key={`linescale-${Qindex}`} style={{ padding: "5px", border: "1px solid", marginBottom: "10px" }}>
                                        <div>{t.Question}</div>
                                        <div style={{ textAlign: "center" }}>
                                            {t.options.map((option, optionIndex) => {
                                                return (
                                                    <div key={`checkbox-options-${Qindex}-${optionIndex}`} className="form-check-inline" style={{ marginLeft: "5%" }}>
                                                        <Input label={option.value} className="form-check-input" id={option.value + t.id} name={t.Question} type="radio" required={t.required} description={t.description} value={option.value} checked={option.response} onChange={(event) => { handleChangeDynamicOptions(event, t.id, `${optionIndex + 1}`, t.Question) }} />
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                )
                            }
                            else if (t.type.value === "select") {
                                return (
                                    <div key={`select-${Qindex}`} style={{ padding: "5px", border: "1px solid", marginBottom: "2%" }}>
                                        <Input type={t.type.value} label={t.Question} value={t.response} required={t.required} options={options[t.id]} onChange={(event) => { handleChangeselect(event, t.id) }} />
                                    </div>

                                )
                            }
                            else if (t.type.value === "file") {
                                return (
                                    <div key={`select-${Qindex}`} style={{ padding: "5px", border: "1px solid", marginBottom: "2%" }}>
                                        <Input type={t.type.value} name="file" label={t.Question} required={t.required} onChange={(event) => { handleChangeFile(event, t.id) }} />
                                    </div>

                                )
                            }
                            else {
                                return (
                                    <div key={`other-${Qindex}`} style={{ padding: "5px", border: "1px solid", marginBottom: "2%" }}>
                                        <Input type={t.type.value} label={t.Question} required={t.required} onChange={(event) => { handleChange(event, t.id) }} />
                                    </div>

                                )
                            }




                        })}


                        <Row style={{ display: "flex", justifyContent: "left" }} >

                            <button type="submit" className="btn btn-primary btn-lg col-12">
                                Submit
                            </button>


                        </Row>
                    </form>
                </Card>

            </div>
        </Fragment>


    )


};
export default PublishForms;


