import React, { useEffect, useState } from "react";
import classes from "./FormsList.module.css";
import Card from "@mui/material/Card";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PublishIcon from "@mui/icons-material/Publish";
import DownloadIcon from "@mui/icons-material/Download";
import AddForm from "./AddForm/AddForm";
import { useNavigate } from "react-router";
import RecentResponse from "./RecentResponse/RecentResponse";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ForumIcon from "@mui/icons-material/Forum";
import axios from "axios";
import Swal from "sweetalert2";
import { BASE_URL } from "../../variables/api";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import {
  ModalBody,
  Modal,
  ModalHeader,
  ModalFooter,
  Form,
  Label,
} from "reactstrap";
import Input from "../../components/Input/Input";
const FormsList = (props) => {
  const [surveys, setSurveys] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [grades, setGrades] = useState([]);
  const [modal, setModal] = useState(false);
  const [formData, setFormData] = useState({ name: "", id: "" });

  useEffect(() => {
    (async () => {
      $("#dataTable").DataTable().destroy();
      const res = await axios.get(
        `${BASE_URL}/surveys/getallsurveysforformlists.php`
      );
      setSurveys(res.data.data.surveys);
      setDepartments(res.data.data.departments);
      setGrades(res.data.data.grades);
      $("#dataTable").DataTable({
        lengthMenu: [
          [5, 10, 50, -1],
          [5, 10, 50, "All"],
        ],
      });
      $("#dataTable").DataTable();
    })();
  }, [props.pending]);
  let navigate = useNavigate();
  const handleClick = () => {
    navigate("/add-form");
  };
  const handleDuplicate = (id) => {
    setModal(true);
    setFormData((prevstate) => ({ ...prevstate, id: id }));
  };
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setFormData((prevstate) => ({ ...prevstate, [name]: value }));
  };
  const handleEdit = (id) => {
    navigate(`/editforms/${id}`);
  };
  const handlePublish = (id) => {
    navigate(`/Publish/${id}`);
  };
  const handleView = (id) => {
    navigate(`/myforms/${id}`);
  };
  const handleSummary = (id) => {
    navigate(`/Summary/${id}`);
  };
  const handleDelete = (id) => {
    Swal.fire({
      title: "Do you want to Delete Form?",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: `YES`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire("Saved!", "", "success");
        axios
          .post(`${BASE_URL}/surveys/deletesurvey.php`, { id: id })
          .then((res) => {
            props.setPending(!props.pending);
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };
  const submitHandler = (e) => {
    e.preventDefault();
    axios
      .post(`${BASE_URL}/surveys/addduplicate.php`, { formData: formData })
      .then((res) => {
        Swal.fire("Survey Added Successfully", "", "success");
      })
      .finally(() => {
        setFormData({ name: "", id: "" });
        props.setPending(!props.pending);
        setModal(false);
      });
  };
  const handleSurveyResponsesView = (id) => {
    navigate(`/Responses/${id}`);
  };
  return (
    <div>
      <h2 className={classes.h2}>Forms List</h2>
      <div className="row">
        <Card className="col-md-8 order-2 order-md-1">
          <div className="table-responsive">
            <table id="dataTable" className="display">
              <thead>
                <tr>
                  <td>ID</td>
                  <td>Name</td>
                  <td>Owner</td>
                  <td>Creation Date</td>
                  <td>Published</td>
                  <td>Published by</td>
                  <td>Published Date</td>
                  <td>Send To</td>
                  <td>Filter</td>
                  <td>Actions</td>
                </tr>
              </thead>

              <tbody>
                {surveys?.map((t, index) => {
                  if (props.user.id === t.user_id && props.user.role_id !== 1) {
                    return (
                      <tr key={index}>
                        <td className={classes.info}>
                          {/* <InsertDriveFileIcon style={{ color: "#3278bd" }} /> */}
                          {t.sid}
                        </td>
                        <td className={classes.info}>{t.name}</td>
                        <td className={classes.info}>{t.owner}</td>
                        <td className={classes.info}>{t.date}</td>
                        <td className={classes.info}>
                          {t.published == 1 ? "Yes" : "No"}
                        </td>
                        <td className={classes.info}>{t.publishedby}</td>
                        <td className={classes.info}>{t.publisheddate}</td>

                        <td
                          style={{ fontSize: "12px" }}
                          className={classes.info}
                        >
                          {t.filtergrades?.length > 0
                            ? grades
                                ?.filter(
                                  (m) => t.filtergrades?.search(m.id) !== -1
                                )
                                ?.map((m) => m.grade)
                                ?.join(", ")
                            : " "}
                          {", "}
                          {t.filterdept?.length > 0
                            ? departments
                                ?.filter(
                                  (m) => t.filterdept?.search(m.id) !== -1
                                )
                                ?.map((m) => m.department)
                                ?.join(", ")
                            : " "}
                        </td>
                        <td
                          style={{ fontSize: "12px" }}
                          className={classes.info}
                        >
                          {t.sendto?.replaceAll("_", " ")}
                        </td>

                        <td
                          style={{ justifyContent: "space-between" }}
                          className={classes.info}
                        >
                          <a
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Publish"
                          >
                            <PublishIcon
                              className={classes.Icon}
                              onClick={() => {
                                handlePublish(t.sid);
                              }}
                            />
                          </a>
                          <a
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Duplicate"
                          >
                            <ContentCopyIcon
                              onClick={() => {
                                handleDuplicate(t.sid);
                              }}
                              className={classes.Icon}
                            />
                          </a>
                          <a
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Form Responses"
                          >
                            <ForumIcon
                              className={classes.Icon}
                              onClick={() => {
                                handleSurveyResponsesView(t.sid);
                              }}
                            />
                          </a>
                          <a
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Preview"
                          >
                            <RemoveRedEyeIcon
                              onClick={() => {
                                handleView(t.sid);
                              }}
                              className={classes.Icon}
                            />
                          </a>
                          <a
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Edit"
                          >
                            <EditIcon
                              onClick={() => {
                                handleEdit(t.sid);
                              }}
                              className={classes.Icon}
                            />
                          </a>
                          <a
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Summary"
                          >
                            <AssessmentIcon
                              onClick={() => {
                                handleSummary(t.sid);
                              }}
                              className={classes.Icon}
                            />
                          </a>

                            {window.location.href.search("iatseg.org") != -1 ?(
                               <a
                               data-toggle="tooltip"
                               data-placement="right"
                               title="Download Responses as CSV"
                               href={`https://survey.iatseg.org/backend/surveys/export.php?id=${t.sid}`}
                             >
                               <DownloadIcon className={classes.Icon} />
                             </a>
                            ) :(
                              <a
                              data-toggle="tooltip"
                              data-placement="right"
                              title="Download Responses as CSV"
                              href={`https://survey.ivyis.org/backend/surveys/export.php?id=${t.sid}`}
                            >
                              <DownloadIcon className={classes.Icon} />
                            </a>
                            )}

                            
                         

                          <a
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Delete"
                          >
                            <DeleteIcon
                              className={classes.Icon}
                              onClick={() => {
                                handleDelete(t.sid);
                              }}
                            />
                          </a>
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr key={index}>
                        <td className={classes.info}>
                          <InsertDriveFileIcon style={{ color: "#3278bd" }} />
                        </td>
                        <td className={classes.info}>{t.name}</td>
                        <td className={classes.info}>{t.owner}</td>
                        <td className={classes.info}>{t.date}</td>
                        <td className={classes.info}>
                          {t.published == 1 ? "Yes" : "No"}
                        </td>
                        <td className={classes.info}>{t.publishedby}</td>
                        <td className={classes.info}>{t.publisheddate}</td>

                        <td
                          style={{ fontSize: "12px" }}
                          className={classes.info}
                        >
                          {t.filtergrades?.length > 0
                            ? grades
                                ?.filter(
                                  (m) => t.filtergrades?.search(m.id) !== -1
                                )
                                ?.map((m) => m.grade)
                                ?.join(", ")
                            : " "}
                          {", "}
                          {t.filterdept?.length > 0
                            ? departments
                                ?.filter(
                                  (m) => t.filterdept?.search(m.id) !== -1
                                )
                                ?.map((m) => m.department)
                                ?.join(", ")
                            : " "}
                        </td>
                        <td
                          style={{ fontSize: "12px" }}
                          className={classes.info}
                        >
                          {t.sendto?.replaceAll("_", " ")}
                        </td>

                        <td
                          style={{ justifyContent: "space-between" }}
                          className={classes.info}
                        >
                          <a
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Publish"
                          >
                            <PublishIcon
                              className={classes.Icon}
                              onClick={() => {
                                handlePublish(t.sid);
                              }}
                            />
                          </a>
                          <a
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Duplicate"
                          >
                            <ContentCopyIcon
                              onClick={() => {
                                handleDuplicate(t.sid);
                              }}
                              className={classes.Icon}
                            />
                          </a>
                          <a
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Form Responses"
                          >
                            <ForumIcon
                              className={classes.Icon}
                              onClick={() => {
                                handleSurveyResponsesView(t.sid);
                              }}
                            />
                          </a>
                          <a
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Preview"
                          >
                            <RemoveRedEyeIcon
                              onClick={() => {
                                handleView(t.sid);
                              }}
                              className={classes.Icon}
                            />
                          </a>
                          <a
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Edit"
                          >
                            <EditIcon
                              onClick={() => {
                                handleEdit(t.sid);
                              }}
                              className={classes.Icon}
                            />
                          </a>
                          <a
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Summary"
                          >
                            <AssessmentIcon
                              onClick={() => {
                                handleSummary(t.sid);
                              }}
                              className={classes.Icon}
                            />
                          </a>

                         {window.location.href.search("iatseg.org") != -1 ?(
                           <a
                           data-toggle="tooltip"
                           data-placement="right"
                           title="Download Responses as CSV"
                           href={`https://survey.iatseg.org/backend/surveys/export.php?id=${t.sid}`}
                         >
                           <DownloadIcon className={classes.Icon} />
                         </a>
                         ) :(
                          <a
                          data-toggle="tooltip"
                          data-placement="right"
                          title="Download Responses as CSV"
                          href={`https://survey.ivyis.org/backend/surveys/export.php?id=${t.sid}`}
                        >
                          <DownloadIcon className={classes.Icon} />
                        </a>
                         )}

                          <a
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Delete"
                          >
                            <DeleteIcon
                              className={classes.Icon}
                              onClick={() => {
                                handleDelete(t.sid);
                              }}
                            />
                          </a>
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </div>
        </Card>
                <div className="col-md-4 order-1 order-md-2 mb-2">

        <AddForm handleClick={handleClick} />
                </div>
      </div>
      <h2 className={classes.h2}>Last Form Responses</h2>
      <div className={classes.Responses}>
        <RecentResponse
          responses={props.responses}
          recentResponses={props.recentResponses}
          survey={surveys}
        />
      </div>
      <Modal isOpen={modal}>
        <ModalHeader>Add Survey Duplicate</ModalHeader>
        <ModalBody>
          <Form onSubmit={submitHandler}>
            <Label>New Survey Name</Label>
            <Input
              value={formData.name}
              name="name"
              type="text"
              onChange={(e) => {
                handleChange(e);
              }}
            />
            <button
              type="submit"
              style={{ width: "100%" }}
              className="btn btn-primary"
            >
              Submit
            </button>
          </Form>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-secondary" onClick={() => setModal(false)}>
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default FormsList;
